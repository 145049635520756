<template>
  <div class="productcenter">
    <div class="bg">
      <img src="@imgs/cpzx.png" width="195px" height="92px" alt="" class="cpzx" />
    </div>
    <div class="prolist">
      <div class="proitem" v-for="item in proList" :key="item.id" @click="toDetail(item)">
        <img class="img-width" :src="item.imgaddr" alt="" />
        <p>{{ item.title }}</p>
        <p>{{ item.comm }}</p>
        <p>{{ item.wh }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      proList: [
        {
          id: 1,
          imgaddr: require("@imgs/1.png"),
          title: "感冒灵颗粒(999)",
          comm: "华润三九医药股份有限公司",
          spec: "10g*9袋",
          wh: "【批准文号】国药准字Z44021940",
        },
        {
          id: 2,
          imgaddr: require("@imgs/2.png"),
          title: "藿香正气水",
          comm: "四川天府康达药业集团府庆制药有限公司",
          spec: "10ml*10支",
          wh: "【批准文号】国药准字Z51022378",
        },
        {
          id: 3,
          imgaddr: require("@imgs/3.png"),
          title: "小柴胡颗粒(有糖)",
          comm: "广州白云山光华制药股份有限公司",
          spec: "10g*10袋",
          wh: "【批准文号】国药准字Z44020211",
        },
      ],
    };
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: "/goodDetail?id=" + item.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.productcenter {
  .prolist {
    width: 960px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;

    .proitem {
      width: 250px;
      background: #ffffff;
      border: 1px solid #cccccc;
      // margin: 13.5px 0;
      margin-bottom: 30px;
      padding: 0 10px;

      img {
        width: 200px;
        height: 180px;
      }
    }
  }
}
</style>